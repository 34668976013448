<template>
  <div>
    <DisDetail :contextVo = "contextVo" :weatherLogin="weatherLogin"></DisDetail>
  </div>
</template>

<script>
import DisDetail from '@/components/disDetails'
import req from '@/api/search'

export default {
  name: "DisplayDetail",
  components: {
    DisDetail
  },
  data(){
    return {
      contextVo : '',
      weatherLogin : false
    }
  },
  created() {
    req.getDetails({queryString: this.$route.params.id}).then((res)=>{
      this.contextVo = res.data.result
    })
    req.checkLoginStatus().then((res)=>{
      if(res.data.result == 'yes') {
        this.weatherLogin = true
        this.$eventBus.$emit('changeLoginStatus',true)
      }
    }).catch((err)=>{
      console.log(err.code)
      this.weatherLogin = false
    })
  }
}
</script>

<style scoped>

</style>
