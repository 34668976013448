<template>
  <div class="title">
    交流干货库
  </div>
</template>

<script>
export default {
  name: "SurfaceBanner"
}
</script>

<style scoped>
.title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bolder;
  font-size: 36px;
  margin-bottom: 30px;
  margin-top: 60px;
}
</style>
