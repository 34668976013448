<template>
  <div>
    <SurfaceBanner></SurfaceBanner>
    <div class="main-container">
      <div class="page-width">
          <SearchToolKit></SearchToolKit>
<!--          <div class="font-1">CHM热搜 ></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import SurfaceBanner from "@/components/surfaceBanner";
import SearchToolKit from "@/components/searchToolKit";

export default {
  name: 'displayReach',
  components:{
    SurfaceBanner,SearchToolKit
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-width{
  width: 50%;
}

.font-1 {
  font-family: Hei;
  font-size: 16px;
  font-style: oblique;
  font-weight: bolder;
}
</style>
