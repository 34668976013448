import VueRouter from "vue-router";
import DisplayReach from '@/pages/DisplayReach'
import DisplayResult from '@/pages/DisplayResult'
import DisplayDetail from '@/pages/DisplayDetail'
// import DoRegister from '@/pages/Register'
import HandleCode from '@/pages/HandleCode'
import DoLogin from "@/pages/DoLogin";

export default new VueRouter({
    mode: 'history',
    routes:[
        {
            name: 'displayReach',
            path: '/displayReach',
            component: DisplayReach
        },
        {
            name: 'displayResult',
            path: '/displayResult/:reachKey',
            component: DisplayResult,
        },
        {
            name: 'displayDetail',
            path: '/displayDetail/:id',
            component: DisplayDetail
        },
        {
            name: 'login',
            path: '/doLogin',
            component: DoLogin
        },
        // {
        //     name: 'register',
        //     path: '/register',
        //     component: DoRegister
        // },
        {
            //默认路由
            name: 'default',
            path: '/',
            component: DisplayReach
        },
        {
            name: 'handleCode',
            path: '/handleCode',
            component: HandleCode
        }
    ]
})
