<template>
  <div>
      <div v-for="(result,index) in searchResult" :key="index" class="layout">
        <div class="line">
          <div class="linkStyle" @click="goDetails(result.id)">{{result.title}}</div><div class="publish-date">发布时间：{{result.publishDate | convertDate('yyyy-MM-dd')}}</div>
        </div>
        <div>
          {{result.subTitle}}
        </div>
        <div v-html="result.textPart">
        </div>
      </div>
  </div>
</template>

<script>
import dateConvert from '@/utils/dateFormatUtils'

export default {
  name: "resultList",
  props: {
    searchResult: Array
  },
  methods: {
    goDetails(id){
      const reUrl = this.$router.resolve({
        name: 'displayDetail',
        params:{
          id: id
        }
      })
      window.open(reUrl.href,'_blank')
    }
  },
  filters:{
    convertDate(value,args){
      return dateConvert.formatDate(value,args)
    }
  }
}
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  margin-left: 30px;
}
.linkStyle {
  font-size: medium;
  font-weight: bolder;
  color: #3388ff;
  text-decoration-line: underline;
  text-decoration-color: #3388ff;
}
.line{
  display: flex;
  justify-content: flex-start;
}
.publish-date {
  margin-left: 50px;
  font-style: oblique;
}
</style>
