<template>
  <div class="pageNumberContainer">
    <div class="tagItem">
      找到 {{totalRecords}} 条 - 共 {{ pageTotal }} 页
    </div>
    <div :class="currentIndex === -2 || isClick === -2?'tagItemActive':'tagItem'" @mouseenter="mouseEnter(-2)"
         @mouseleave="mouseLeave" @click="prvPage">
      [上一页]
    </div>
    <div v-for="item in pageTotal" :key="item"
         :class="currentIndex === item || isClick === item?'tagItemActive':'tagItem'" @mouseenter="mouseEnter(item)"
         @mouseleave="mouseLeave" @click="tagClick(item)">
      [{{ item }}]
    </div>
    <div :class="currentIndex === -3 || isClick === -3?'tagItemActive':'tagItem'" @mouseenter="mouseEnter(-3)"
         @mouseleave="mouseLeave" @click="nextPage">
      [下一页]
    </div>
  </div>
</template>

<script>
export default {
  name: "pageControl",
  props: {
    pageTotal: Number,
    currentPage: Number,
    pageSize: Number,
    totalPages: Number,
    totalRecords: Number
  },
  data() {
    return {
      currentIndex: -1,
      isClick: -1
    }
  },
  methods: {
    mouseEnter(index) {
      this.currentIndex = index
    },
    mouseLeave() {
      this.currentIndex = -1
    },
    tagClick(page){
      this.isClick = page
      this.$emit('goPage',page)
    },
    nextPage(){
      this.currentIndex++
      this.$emit('goPage',this.currentIndex)
    },
    prvPage(){
      this.currentIndex--
      this.$emit('goPage',this.currentIndex)
    }
  }
}
</script>

<style scoped>
.pageNumberContainer {
  display: flex;
  flex-direction: row;
}

.tagItem {
  flex-grow: 0;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px 5px 5px 5px;
  flex-shrink: 1;
  font-style: oblique;
  color: #354b70;
  font-weight: bold;
}

.tagItemActive {
  flex-grow: 0;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px 5px 5px 5px;
  flex-shrink: 1;
  font-style: oblique;
  color: #6597f8;
  font-weight: bold;
}
</style>
