import request from '@/utils/request'
import permissionRequest from '@/utils/permissionRequest'
import sysRequest from "@/utils/sysRequest";

//获取搜索结果
function getResult(data = {}){
    return doPostRequest(data, '/webSitSearch')
}

function getDetails(data){
    return doPostRequest(data, '/getDetail')
}

function getFileDownLoad(data){
    window.open('https://www.yiyaolishi.com:8080/chmplatform/hasPermission/getFileDownLoad/'+data,'_blank')
    // window.open('http://localhost:8080/chmplatform/hasPermission/getFileDownLoad/'+data,'_blank')
}

function checkLoginStatus(){
    return doGetPermissionRequest({},'/checkLoginStatus')
}

function getFileDownLoadPop(data){
    return doPostRequest(data,'/getFileDownLoad')
}

function getUserInfo(){
    return doGetPermissionRequest({},'/getUserInfo')
}

function getRandomImage(){
    return sysRequest.get('/sys/randomImage/abc')
}

function login(data){
    return sysRequest({
        method: 'POST',
        url: '/wxsys/wxwslogin?code='+data
    })
}

function logout(){
    return sysRequest({
        method: 'POST',
        url: '/sys/logout'
    })
}

function getAllTags(){
    return doPostRequest({},'/getAllTags')
}

function doPostRequest(data,url){
    return request({
        method: 'POST',
        url,data
    })
}


function doGetPermissionRequest(data,url){
    permissionRequest.defaults.headers.get['x-access-token'] = localStorage.getItem('token')
    return permissionRequest({
        method: 'GET',
        url,data
    })
}

export default {
    getResult,
    getDetails,
    getFileDownLoad,
    getUserInfo,
    getFileDownLoadPop,
    getRandomImage,
    login,
    logout,
    checkLoginStatus,
    getAllTags
}
