import re from "@/api/search";

function getUserInfo() {
    let userInfo = {
        userName: '',
        userId: '',
        text: '',
    }
    re.getUserInfo().then((res) => {
        userInfo.text = '退出'
        userInfo.userName = res.data.result.userName
    }).catch(() => {
        userInfo.text = '登录'
        userInfo.userName = '注册'
    })
    return userInfo
}

function getLoginStatus(){
    let r = false
    re.checkLoginStatus().then(()=>{
        r = true
    }).catch(()=>{
        console.log('这里又执行了')
        r = false
    })
    return r
}

export default {
    getUserInfo,
    getLoginStatus
}
