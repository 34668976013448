import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from './router'
import vDialog from 'v-dialogs'

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(vDialog)


new Vue({
  render: h => h(App),
  router: router,
  beforeCreate() {
    Vue.prototype.$eventBus = this
  }
}).$mount('#app')
