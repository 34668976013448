<template>
  <div class="layout">
    <div class="headLine">CHM</div>
    <hr class="line"/>
    <div class="layout-row">
      <div class="tags">
        <div v-for="(va,index) in contextVo.tags" :key="index" class="tagItem">
          #{{va}}
        </div>
      </div>
      <div class="title">{{contextVo.title}}</div>
      <div class="subTitle">{{contextVo.subTitle}}</div>
      <div class="contentContainer">
        <div class="subTitle">发布时间：{{contextVo.publishDate  | convertDate('yyyy-MM-dd')}}</div>
        <div class="subTitle">作者：{{contextVo.author}}</div>
        <div class="subTitle">来源：{{contextVo.original}}</div>
      </div>
      <div class="contentContainer">
        <div class="content" v-html="contextVo.textPart"></div>
      </div>
    </div>
    <hr class="line"/>
    <div class="attachmentTitle">完整版资料</div>
    <div v-for="(va,index) in contextVo.files" :key="index">
      <a @click="downLoad(va['fileId'],va['fileName'])">{{va['fileName']}}</a>
    </div>
  </div>
</template>

<script>
import re from '@/api/search'
import dateConvert from '@/utils/dateFormatUtils'

export default {
  name: "disDetails",
  props: {
    contextVo: {
      title: String,
      subTitle: String,
      author: String,
      original: String,
      textPart: String,
      publishDate: String,
      files: Array
    },
    weatherLogin: Boolean
  },
  methods: {
    downLoad(id){
      if(typeof localStorage.getItem('token') === 'undefined' || localStorage.getItem('token') === null){
        this.$dlg.alert('只有登录后才可以下载哦',{
          messageType: 'warning',
          title: '提示',
          icon: false,
          language: 'cn'
        })
      }else{
        // if(this.weatherLogin) {
          re.getFileDownLoad(id + '?token=' + localStorage.getItem('token'))
        // }else {
        //   this.$dlg.alert('只有登录后才可以下载哦',{
        //     messageType: 'warning',
        //     title: '提示',
        //     icon: false,
        //     language: 'cn'
        //   })
        // }
      }
    },
    downLoadPop(id){
      re.getFileDownLoadPop({queryString: id}).then((res)=>{
        console.log(res.data)
      })
    }
  },
  filters:{
    convertDate(value,args){
      return dateConvert.formatDate(value,args)
    }
  }
}
</script>

<style scoped>

.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.headLine {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bolder;
  font-size: 40px;
}

.line {
  color: #3388ff;
  width: 100%;
  margin-bottom: 20px;
}

.layout-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.title {
  width: 100%;
  margin-top: 30px;
  font-size: xx-large;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bolder;
}

.subTitle {
  width: 100%;
  margin-top: 10px;
  font-size: large;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: medium;
  font-weight: bold;
  color: #3388ff;
}

.tagItem {
  margin-left: 10px;
  margin-right: 10px;
}

.contentContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.content {
  width: 80%;
  margin-top: 10px;
  text-align: start;
}

.attachmentTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: medium;
  font-weight: bold;
  color: #3388ff;
}
</style>
