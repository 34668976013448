<template>
  <div class="layout">
    <a @click="goLoginOrLogout">{{ userInfo.text }}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a @click="goRegister">{{ userInfo.userName }}</a>
  </div>
</template>

<script>
import re from '@/api/search'
import common from '@/api/common'

export default {
  name: "ifHasPermission",
  props:{
    ifLogin:Boolean
  },
  created() {
    if(this.inIfLogin){
      this.userInfo = common.getUserInfo({
        unionid:localStorage.getItem('unionid')
      })
    }else {
      this.userInfo.text = '登录'
      this.userInfo.userName = '注册'
    }
  },
  data(){
    return {
      userInfo: {
        text: '登录',
        userId: '',
        userName: '注册'
      },
      inIfLogin : this.ifLogin
    }
  },
  methods:{
    goRegister(){
      if(!this.inIfLogin){
        // window.open('http://www.yiyaolishi.com:3000/user/register')
        // window.open('https://www.yiyaolishi.com:3000')
      }
      // const reUrl = this.$router.resolve({
      //   name: 'register'
      // })
      // window.open(reUrl.href,'_blank')
    },
    goLoginOrLogout(){
      const pa = {}
      if(this.inIfLogin){
        re.logout().then(()=>{
          localStorage.removeItem('token')
        })
        pa.name = 'default'
        this.$eventBus.$emit('changeLoginStatus',false)
      }else {
        pa.name = 'login'
      }
      this.$router.push(pa)
    }
  }
}
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 50px;
}
</style>
