<template>
  <div>
    <div class="layout-row">
      <input v-model="reachKey" class="input" type="text"/>
      <button class="button" @click="doSearch(reachKey)">查一查</button>
    </div>
    <div class="tagContainer">
      <div v-for="(result,index) in allTags" :key="index" :class="currentIndex === index || isClick === index?'tagItemActive':'tagItem'"
           @click="tagClick(index,result.caName)" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave">{{result.caName}}</div>
    </div>
  </div>
</template>

<script>
import re from '@/api/search'

export default {
  name: "searchToolKit",
  data(){
    return {
      reachKey: this.initReachKey,
      allTags:[],
      currentIndex: -1,
      isClick: -1
    }
  },
  mounted() {
    re.getAllTags().then((res)=>{
      this.allTags = res.data.result
    })
  },
  props:{
    initReachKey: String
  },
  methods: {
    doSearch(reachKey){
      this.$router.push({
        name: 'displayResult',
        params: {
          reachKey: reachKey
        }
      },()=>{},()=>{})
      this.$emit('search',reachKey)
    },
    mouseEnter(index){
      this.currentIndex = index
    },
    mouseLeave(){
      this.currentIndex = -1
    },
    tagClick(index,value){
      this.isClick = index
      if(this.reachKey === undefined) {
        this.reachKey = value
      }else {
        this.reachKey = this.reachKey + ' ' + value
      }
    }
  }
}
</script>

<style scoped>
.layout-row {
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.button {
  width: 100px;
  height: 40px;
  box-sizing: border-box;
  background-color: #38f;
  border: none;
  color: white;
  font-size: 16px;
  left: 500px;
  cursor: pointer;
  border-radius: 3px;
}

.input {
  width: 700px;
  height: 40px;
  box-sizing: border-box;
  text-indent: 10px;
  font-size: 16px;
  margin-right: 3px;
}

.tagContainer{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
}

.tagItem {
  flex-grow: 0;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px 5px 5px 5px;
  flex-shrink: 1;
  font-style: oblique;
  color: #354b70;
  font-weight: bold;
  background-color: #efefef;
  border-radius: 5px;
}

.tagItemActive {
  flex-grow: 0;
  margin-left: 10px;
  margin-top: 10px;
  padding: 5px 5px 5px 5px;
  flex-shrink: 1;
  font-style: oblique;
  color: #6597f8;
  font-weight: bold;
  background-color: #b4caff;
  border-radius: 5px;
}
</style>
