<template>
  <div>'登录跳转中....'</div>
</template>

<script>
import re from '@/api/search'

export default {
  name: "handleCode",
  created() {
    re.login(this.$route.query.code).then((res)=>{
      localStorage.setItem('token',res.data.result.token)
      localStorage.setItem('unionid',res.data.result.sysUserInfoBO.unionid)
      this.$router.push("displayReach")
      this.$eventBus.$emit('changeLoginStatus',true)
    })
  }
}
</script>

<style scoped>

</style>
