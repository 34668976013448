<template>
  <div class="layout">
    <div class="title">
      交流干货库
    </div>
    <SearchToolKit v-on:search="doSearch" :initReachKey="reachKey"></SearchToolKit>
    <ResultList :searchResult="searchResult"></ResultList>
    <PageControl :pageTotal="totalPages" :pageSize="pageSize"
                 :totalRecords="totalRecords"
                 @goPage="goPage"
    ></PageControl>
  </div>
</template>

<script>
import SearchToolKit from "@/components/searchToolKit";
import ResultList from "@/components/resultList";
import DoSearch from "@/api/search";
import PageControl from "@/components/pageControl";

export default {
  name: "DisplayResult",
  components: {
    SearchToolKit, ResultList, PageControl
  },
  data() {
    return {
      reachKey: '',
      searchResult: [],
      startPage: 1,
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0
    }
  },
  created() {
    this.doSearch(this.$route.params.reachKey)
  },
  methods: {
    doSearch(data) {
      this.reachKey = data;
      DoSearch.getResult({
        queryString: this.$route.params.reachKey,
        startPage: this.startPage,
        pageSize: this.pageSize
      }).then((res) => {
        console.log(res)
        if (res.status == 200) {
          this.searchResult = res.data.result.records
          this.currentPage = this.startPage
          this.totalRecords = res.data.result.totalRecords
          this.totalPages = res.data.result.totalPages
        }
      })
    },
    goPage(pageNumber) {
      DoSearch.getResult({
        queryString: this.reachKey,
        startPage: pageNumber,
        pageSize: this.pageSize
      }).then((res) => {
        console.log(res)
        if (res.status == 200) {
          this.searchResult = res.data.result.records
          this.currentPage = this.startPage
          this.totalRecords = res.data.result.totalRecords
          this.totalPages = res.data.result.totalPages
        }
      })
    }
  }
}
</script>

<style scoped>
.layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bolder;
  font-size: 20px;
}
</style>
