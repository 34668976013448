<template>
  <div>
    <IfHasPermission :ifLogin="ifLogin" :key="ifLogin"></IfHasPermission>
    <router-view></router-view>
  </div>
</template>

<script>
import IfHasPermission from '@/components/ifHasPermission'


export default {
  name: 'mainSurface',
  components: {
    IfHasPermission
  },
  data() {
    return {
      ifLogin: false
    }
  },
  mounted() {
    this.$eventBus.$on('changeLoginStatus', this.changeLoginStatus)
  },
  destroyed() {
    this.$eventBus.$off('changeLoginStatus')
  },
  methods: {
    changeLoginStatus(status) {
      this.ifLogin = status
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
