<template>
  <div>
    <SurfaceBanner></SurfaceBanner>
    <iframe :src="src" frameborder="0" style="width:300px;height:600px" sendbox="allow-scripts allow-top-navigation allow-same-origin"></iframe>
  </div>
</template>
<script>
import SurfaceBanner from '@/components/surfaceBanner'

export default {
  name: "DoLogin",
  components: {
    SurfaceBanner
  },
  data(){
    return {
      src:'https://open.weixin.qq.com/connect/qrconnect?appid=wx5a493f1e4703cd3a&redirect_uri=http://www.yiyaolishi.com/handleCode&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect'
    }
  }
}
</script>

<style scoped>

</style>
